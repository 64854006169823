import { API_CLIENT_ID, API_CLIENT_SECRET, API_URL, API_GRANT_TYPE } from './Config';
import { startLoading, endLoading, SnackBar, handleHTTPError, ShowRemark } from './CommonActions';
import store from '../store';
import axios from 'axios';



export function MyBookings(prameters) {

    return function (dispatch) {
        dispatch(startLoading());
        fetch(API_URL + '/api/admin/bookings', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + store.getState().Auth.authToken.access_token
            },
            body: JSON.stringify(prameters),
        }).then(handleHTTPError)
            .then((response) => {
                dispatch(endLoading());
                response.json().then((json) => {
                    dispatch(endLoading());
                    dispatch(storeBookings(json));
                });
            }).catch((error) => {
                dispatch(endLoading());
                dispatch(SnackBar(true, 'Could not load data', 'error'));
            });
    }

}



export function storeBookings(list) {
    return {
        type: 'STORE_BOOKINGS',
        list: list
    }
}


export function GetBooking(bookingID) {

    return function (dispatch) {
        dispatch(startLoading());
        fetch(API_URL + '/api/admin/bookings?id=' + bookingID, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + store.getState().Auth.authToken.access_token
            },

        }).then(handleHTTPError)
            .then((response) => {
                dispatch(endLoading());
                response.json().then((json) => {
                    dispatch(endLoading());
                    dispatch(storeActiveBooking(json));
                });
            }).catch((error) => {
                dispatch(endLoading());
                dispatch(SnackBar(true, 'Could not load data', 'error'));
            });
    }

}

export function storeActiveBooking(booking) {
    return {
        type: 'STORE_ACTIVE_BOOKING',
        booking: booking
    }
}


export function LockActiveActivity(act) {
    return {
        type: 'LOCK_ACTIVE_BOOKING',
    }
}


export function UpdateBookingStatus(data) {

    return function (dispatch) {
        dispatch(startLoading());
        dispatch(SnackBar(true, 'Updating booking...', 'info'));
        fetch(API_URL + '/api/admin/bookings/update', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + store.getState().Auth.authToken.access_token
            },
            body: JSON.stringify({ bid: data.id, status: data.status, remark: data.remark }),
        }).then(handleHTTPError)
            .then((response) => {
                dispatch(endLoading());
                response.json().then((json) => {
                    dispatch(endLoading());
                    dispatch(GetBooking(data.id));
                    dispatch(SnackBar(true, 'Booking updated', 'success'));
                });
            }).catch((error) => {
                dispatch(endLoading());
                dispatch(SnackBar(true, 'Could not save data', 'error'));
            });
    }

}


export function AddMessage(bookingID, message, visible) {

    return function (dispatch) {
        dispatch(startLoading());
        dispatch(SnackBar(true, 'Adding new message...', 'info'));
        fetch(API_URL + '/api/admin/bookings/message', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + store.getState().Auth.authToken.access_token
            },
            body: JSON.stringify({ bid: bookingID, message: message, visibility: visible }),
        }).then(handleHTTPError)
            .then((response) => {
                dispatch(endLoading());
                response.json().then((json) => {
                    dispatch(endLoading());
                    dispatch(GetBooking(bookingID));
                    dispatch(SnackBar(true, 'Message added', 'success'));
                });
            }).catch((error) => {
                dispatch(endLoading());
                dispatch(SnackBar(true, 'Could not save data', 'error'));
            });
    }

}

export function HideMessage(bookingID, messageID, status) {

    return function (dispatch) {
        dispatch(startLoading());
        dispatch(SnackBar(true, 'Updating message...', 'info'));
        fetch(API_URL + '/api/admin/message/show?mid=' + messageID + '&h=' + status, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + store.getState().Auth.authToken.access_token
            },
        }).then(handleHTTPError)
            .then((response) => {
                dispatch(endLoading());
                response.json().then((json) => {
                    dispatch(endLoading());
                    dispatch(GetBooking(bookingID));
                    dispatch(SnackBar(true, 'Message updated', 'success'));
                });
            }).catch((error) => {
                dispatch(endLoading());
                dispatch(SnackBar(true, 'Could not save data', 'error'));
            });
    }

}


export function RejecBooking(data) {
    return function (dispatch) {
        dispatch(ShowRemark(true, (data) => {
            dispatch(UpdateBookingStatus(data));
            dispatch(ShowRemark(false, null, null));
        }, data))
    }
}

export function clearActiveBooking() { return { type: 'CLEAR_ACTIVE_BOOKING' } }


export function confirmBooking(id) {
    return function (dispatch) {
        dispatch(startLoading());
        dispatch(SnackBar(true, 'Updating booking status...', 'info'));
        fetch(API_URL + '/api/admin/bookings/confirm?id=' + id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + store.getState().Auth.authToken.access_token
            },
        }).then(handleHTTPError)
            .then((response) => {
                response.json().then((json) => {
                    if(json.status) {
                        dispatch(GetBooking(id));
                        dispatch(SnackBar(true, json.message, 'success'));
                    }
                });
            }).catch((error) => {
                dispatch(SnackBar(true, 'Booking status not updated', 'error'));
            });
    }
}