import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import Drawer from './Common/Drawer'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import Notification from '@material-ui/icons/NotificationsActive'
import { compose } from 'redux'
import { connect } from 'react-redux'
import AppBar from './Common/MainAppBar'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Chip from '@material-ui/core/Chip'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import SearchSelect from '../Components/TagInput'
import { MyBookings, clearActiveBooking } from '../Actions/BookingActions'
import { fetchCategories } from '../Actions/CategoryAction'
import { GetSuppliersStausFilter } from '../Actions/SupplierActions'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers'
import './../activity/css/style.css'

const drawerWidth = 240;

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};


const styles = theme => ({

  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },

  button: {
    display: 'block',
    marginTop: theme.spacing.unit * 2,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },

  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },

  fab: {
    margin: theme.spacing.unit,
  },

  extendedIcon: {
    marginRight: theme.spacing.unit,
  },

  wrap: {
    width: 480,
    margin: 'auto',
    marginTop: 100,

  },

  searchIcon: {
    width: theme.spacing(7),
    height: '5px',
    position: 'bottom',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'bottom',
    justifyContent: 'bottom',
    padding: '0',
    color: "white"
  },


  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,

  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '98%'
  },

  button: {
    margin: theme.spacing.unit,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },

  logoWrap: {
    textAlign: 'center',
  },
  toolbar: theme.mixins.toolbar,
  indicator: {
    backgroundColor: '#FFEB3B',
  },
  textAlignCenter: {
    textAlign: 'center'
  }
});

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "black"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#f7a414"
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black"
      },
      "&:hover fieldset": {
        borderColor: "#f7a414"
      },
      "&.Mui-focused fieldset": {
        borderColor: "black"
      }
    }
  }
})(TextField);

const getStatus = (status) => {
  switch (status) {
    case 0: { return "Pending" }
    case 1: { return "Confirmed" }
    case 2: { return "Rejected" }
    case 3: { return "Canceled" }
    case 4: { return "Completed" }
    case 5: { return "Dispute" }
  }
}

const StatusStyle = (status) => {
  switch (status) {
    case 0: { return { backgroundColor: '#90A4AE', color: '#FFF' } }
    case 1: { return { backgroundColor: '#4CAF50', color: '#FFF' } }
    case 2: { return { backgroundColor: '#673AB7', color: '#FFF' } }
    case 3: { return { backgroundColor: '#263238', color: '#FFF' } }
    case 4: { return { backgroundColor: '#00695C', color: '#FFF' } }
    case 5: { return { backgroundColor: '#f44336', color: '#FFF' } }
  }
}

const StatusChip = (status) => {
  return <Chip size="small" style={StatusStyle(status)} label={getStatus(status)}></Chip>
}


const mapStateToProps = (state) => {
  return {
    loading: state.Common.loading,
    loggedIn: state.Auth.loggedIn,
    BookingList: state.Booking.list,
    categories: state.Categories.categories,
    suppliers: state.Suppliers.list,
  }
}

let id = 0;

const mapDispatchToProps = dispatch => ({
  MyBookings: (params) => dispatch(MyBookings(params)),
  clearActiveBooking: () => dispatch(clearActiveBooking()),
  GetSuppliers: (gstatus) => dispatch(GetSuppliersStausFilter(gstatus)),
  fetchCategories: () => dispatch(fetchCategories()),
});


class Tours extends Component {

  constructor(props) {
    super(props);

    this.state = {
      age: '',
      name: 'hai',
      labelWidth: 0,
      value: 'one',
      total_upcomig: '',
      supplier: null,
      bookingdate: null,
      traveldate: null,
      category: "",
      searchkey: null,

    };
    this.props.MyBookings(this.state.supplier, this.state.bookingdate, this.state.traveldate, this.state.category, this.state.searchkey);
    this.props.GetSuppliers('1,3');
    this.props.fetchCategories();
    this.props.clearActiveBooking();
  }

  componentDidMount() {
    document.body.style.background = "#eee";
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    }, () => this.searchBookings());
  };

  handleDateChange(value, field) {
    this.setState({
      [field]: value,
    }, () => this.searchBookings());
  };


  handleTabChange = (tab) => {
    this.setState({ value: tab });
  };


  selectSupplier = (value) => {
    if (value === null) {
      this.setState({
        supplier: null
      }, () => this.searchBookings());
    } else {
      this.setState({
        supplier: value.id
      }, () => this.searchBookings());
    }

  }

  selectCategory = (e) => {
    this.setState({
      category: e.target.value
    }, () => this.searchBookings())
  }
  selectkey = (e) => {
    this.setState({
      searchkey: e.target.value
    }, () => this.searchBookings())
  }

  changeDate = (value, field) => {
    this.setState({
      ...this.state,
      activeSubscription: {
        ...this.state.activeSubscription,
        [field]: value
      }
    });
  }

  onUpdateSubscription = () => {
    this.props.UpdateSubscription(this.state.activeSubscription);
  }


  searchBookings = () => {
    this.props.MyBookings({
      supplier: this.state.supplier,
      bookingdate: this.state.bookingdate,
      traveldate: this.state.traveldate,
      category: this.state.category,
      searchkey: this.state.searchkey,
    });
  }

  getStatus(status) {
    switch (status) {
      case 0: { return "Pending" }
      case 1: { return "Confirmed" }
      case 2: { return "Rejected" }
      case 3: { return "Canceled" }
      case 4: { return "Completed" }
      case 5: { return "Dispute" }
    }
  }

  render() {

    const { classes } = this.props;


    return <Fragment>

      <AppBar title="Dashboard"></AppBar>
      <Drawer currentPage="booking"></Drawer>

      <div style={{ width: "fixed width", marginLeft: drawerWidth + 0, paddingTop: 35, height: 250 }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ backgroundColor: 'white', width: 500, fontWeight: 600, fontSize: 16 }}>Bookings</TableCell>

            </TableRow>

          </TableBody>
        </Table>

        <Table >
          <TableBody>
            <TableRow>
              <TableCell style={{ backgroundColor: '#f2f2f2', fontWeight: 500, paddingTop: 25 }} >
                <Paper className={classes.root} elevation={1} style={{ marginBottom: 10 }}>
                  <table style={{ width: "100%", textAlign: "left", marginBottom: 5 }}>
                    <tr >
                      <td style={{ width: "28%" }}>
                        <FormControl className={classes.formControl}>
                          <TextField
                            label='Search'
                            value={this.state.keyword}
                            onChange={this.selectkey}
                            classes={{
                              root: classes.inputRoot,
                              input: classes.inputInput,
                            }}
                          >
                          </TextField>
                        </FormControl>
                      </td>


                      <td alighn="center" style={{ width: "27%" }}>
                        <FormControl style={{ width: 240 }}>

                          <SearchSelect
                            options={this.props.suppliers}
                            getOptionLabel={option => `${option.work_name}`}
                            getOptionValue={option => `${option.id}`}
                            value={this.state.supplier}
                            placeholder="Supplier"
                            onSelect={this.selectSupplier}
                            onChange={this.handleChange('supplier')}
                          />
                        </FormControl>
                      </td>

                      <td alighn="left" style={{ width: "15%" }} >
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <FormControl className={classes.formControl} >
                            <DatePicker
                              value={this.state.bookingdate !== null ? new Date(this.state.bookingdate) : null}
                              autoOk={true}
                              clearable={true}
                              format="dd/MM/yyyy"
                              inputVariant="standard"
                              onChange={(e) => { this.handleDateChange(e, 'bookingdate') }}
                              style={{ width: 100 }}
                              margin="normal" label="Booking Date"
                            />
                          </FormControl>
                        </MuiPickersUtilsProvider>


                      </td>



                      <td alighn="left" style={{ width: "15%" }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <FormControl className={classes.formControl} >
                            <DatePicker
                              value={this.state.traveldate !== null ? new Date(this.state.traveldate) : null}
                              autoOk={true}
                              clearable={true}
                              format="dd/MM/yyyy"
                              inputVariant="standard"
                              onChange={(e) => { this.handleDateChange(e, 'traveldate') }}
                              style={{ width: 100 }}
                              margin="normal" label="Tour Start"
                            />
                          </FormControl>
                        </MuiPickersUtilsProvider>
                      </td>

                      <td alighn="left" style={{ width: "15%" }}>
                        <FormControl className={classes.formControl}>
                          <InputLabel shrink htmlFor="age-native-label-placeholder">Tour Catogery</InputLabel>
                          <Select
                            native
                            value={this.state.category}
                            onChange={this.selectCategory}
                            inputProps={{
                              name: 'age',
                              id: 'age-native-label-placeholder',
                            }}
                          >
                            <option value="" >All</option>

                            {this.props.categories.map((item) => {
                              return <option value={item.id}>{item.name}</option>;
                            })}

                          </Select>
                        </FormControl>
                      </td>
                    </tr>
                  </table>

                  <div className="bk-menu" >
                    <a style={{ cursor: 'pointer' }} className={this.state.value === 'one' ? 'active' : null} onClick={() => this.handleTabChange('one')}> {this.props.BookingList.unseen.length > 0 ? (<span>{this.props.BookingList.upcoming.length}</span>) : null} Upcoming Tours</a>
                    <a style={{ cursor: 'pointer' }} className={this.state.value === 'two' ? 'active' : null} onClick={() => this.handleTabChange('two')}>On Going Tours</a>
                    <a style={{ cursor: 'pointer' }} className={this.state.value === 'three' ? 'active' : null} onClick={() => this.handleTabChange('three')}> {this.props.BookingList.pending.length > 0 ? (<span>{this.props.BookingList.pending.length}</span>) : null} Pending Tours  </a>
                    <a style={{ cursor: 'pointer' }} className={this.state.value === 'four' ? 'active' : null} onClick={() => this.handleTabChange('four')}>Cancelled Tours</a>
                    <a style={{ cursor: 'pointer' }} className={this.state.value === 'five' ? 'active' : null} onClick={() => this.handleTabChange('five')}>Completed Tours</a>
                    <a style={{ cursor: 'pointer' }} className={this.state.value === 'six' ? 'active' : null} onClick={() => this.handleTabChange('six')}> {this.props.BookingList.dispute.length > 0 ? (<span>{this.props.BookingList.dispute.length}</span>) : null} Disputes </a>
                  </div>

                  <div className="tab-bok">
                    <div className="tab-pan">
                      {this.state.value === 'one' && <TabContainer>
                        <div>
                          {this.props.BookingList.unseen.length > 0 ? (
                            <div >
                              <div ><h3>While you were away...</h3></div>
                              <table cellSpacing={0}>
                                <thead style={{ fontSize: 16 }}>
                                  <tr>
                                    <th className={classes.textAlignCenter}>Tour Date/Ref</th>
                                    <th >Tour Name</th>
                                    <th className={classes.textAlignCenter}>Supplier</th>
                                    <th className={classes.textAlignCenter}>Pax Count</th>
                                    <th className={classes.textAlignCenter}>Booking Date/Time</th>
                                    <th className={classes.textAlignCenter}>Status</th>
                                    <th ></th>
                                  </tr>
                                </thead>
                                <tbody style={{ fontSize: 15 }}>
                                  {this.props.BookingList.unseen.map(row => (
                                    <tr>
                                      <td className={classes.textAlignleft}>{row.start_date}<br />{row.booking_reference}</td>
                                      <td >{row.activity.title}
                                        {row.alert_supplier === 1 ? (<Notification color="error" fontSize="small" />) : null}<br />
                                        <small>Tour ID : {row.activity.tour_id} </small>
                                      </td>
                                      <td className={classes.textAlignleft}> {row.activity.user.supplier_profile.work_name}<br /><small>ID : {row.activity.user.sid}</small></td>
                                      <td className={classes.textAlignleft}>{row.num_adults}A/ {row.num_children}C/ {row.num_infants}I</td>
                                      <td className={classes.textAlignleft}>{row.created_at}</td>
                                      <td className={classes.textAlignleft}>{StatusChip(row.status)}</td>
                                      <td className={classes.textAlignleft}><Button variant="contained" color="primary" size="small" component={Link} to={'/BookingDetails/' + row.id} className={classes.button} style={{ background: "#f7a414", alignContent: 'center', textAlign: "center" }} textalign="center">
                                        <b>View </b> </Button></td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          ) : null}

                          <table cellSpacing={0}>
                            <thead style={{ fontSize: 16 }}>
                              <tr>
                                <th className={classes.textAlignCenter}>Tour Date/Ref</th>
                                <th >Tour Name<br /></th>
                                <th className={classes.textAlignCenter}>Supplier</th>
                                <th className={classes.textAlignCenter}>Pax Count</th>
                                <th className={classes.textAlignCenter}>Booking Date/Time</th>
                                <th className={classes.textAlignCenter}>Status</th>
                                <th>&nbsp;</th>
                              </tr>
                            </thead>
                            <tbody style={{ fontSize: 15 }}>
                              {this.props.BookingList.upcoming.map(row => (
                                <tr>
                                  <td className={classes.textAlignleft}>{row.start_date}<br />{row.booking_reference}</td>
                                  <td className={classes.textAlignleft}>{row.activity.title} <br /><small>Tour ID : {row.activity.tour_id} </small></td>
                                  <td className={classes.textAlignleft}> {row.activity.user.supplier_profile.work_name}<br /><small>ID : {row.activity.user.sid}</small></td>
                                  <td className={classes.textAlignleft}>{row.num_adults}A/ {row.num_children}C/ {row.num_infants}I</td>
                                  <td className={classes.textAlignleft}>{row.created_at}</td>
                                  <td className={classes.textAlignleft}>{StatusChip(row.status)}</td>
                                  <td className={classes.textAlignleft}><Button variant="contained" color="primary" size="small" component={Link} to={'/BookingDetails/' + row.id} className={classes.button} style={{ background: "#f7a414", alignContent: 'center', textAlign: "center" }} textalign="center">
                                    <b>View </b> </Button></td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </TabContainer>}
                      
                      {this.state.value === 'two' && <TabContainer>
                        <div>
                          <table cellSpacing={0}>
                            <thead style={{ fontSize: 16 }}>
                              <tr>
                                <th className={classes.textAlignCenter}>Tour Date/Ref</th>
                                <th>Tour Name</th>
                                <th className={classes.textAlignCenter}>Supplier</th>
                                <th className={classes.textAlignCenter}>Pax Count</th>
                                <th className={classes.textAlignCenter}>Booking Date/Time</th>
                                <th className={classes.textAlignCenter}>Status</th>
                                <th>&nbsp;</th>
                              </tr>
                            </thead>
                            <tbody style={{ fontSize: 15 }}>
                              {this.props.BookingList.ongoing.map(row => (
                                <tr>
                                  <td className={classes.textAlignleft}>{row.start_date}<br />{row.booking_reference}</td>
                                  <td className={classes.textAlignleft}>{row.activity.title}  <br /><small>Tour ID : {row.activity.tour_id} </small></td>
                                  <td className={classes.textAlignleft}> {row.activity.user.supplier_profile.work_name}<br /><small>ID : {row.activity.user.sid}</small></td>
                                  <td className={classes.textAlignleft}>{row.num_adults}A/ {row.num_children}C/ {row.num_infants}I</td>
                                  <td className={classes.textAlignleft}>{row.created_at}</td>
                                  <td className={classes.textAlignleft}>{StatusChip(row.status)}</td>
                                  <td className={classes.textAlignleft} style={{ width: "2%" }} ><Button variant="contained" color="primary" size="small" component={Link} to={'/BookingDetails/' + row.id} className={classes.button} style={{ background: "#f7a414", alignContent: 'center', textAlign: "center" }} textalign="center">
                                    <b>View </b> </Button></td>
                                </tr>
                              ))}
                            </tbody>
                          </table>

                        </div>


                      </TabContainer>}

                      {this.state.value === 'three' && <TabContainer>

                        <div>



                          <table cellSpacing={0}>

                            <thead style={{ fontSize: 16 }}>
                              <tr>
                                <th className={classes.textAlignCenter}>Tour Date/Ref</th>
                                <th>Tour Name</th>
                                <th className={classes.textAlignCenter}>Supplier</th>
                                <th className={classes.textAlignCenter}>Pax Count</th>
                                <th className={classes.textAlignCenter}>Booking Date/Time</th>
                                <th className={classes.textAlignCenter}>Status</th>
                                <th>&nbsp;</th>
                              </tr>

                            </thead>
                            <tbody style={{ fontSize: 15 }}>
                              {this.props.BookingList.pending.map(row => (
                                <tr>
                                  <td className={classes.textAlignleft}>{row.start_date}<br />{row.booking_reference}</td>
                                  <td className={classes.textAlignleft}>{row.activity.title}  <br /><small>Tour ID : {row.activity.tour_id} </small></td>
                                  <td className={classes.textAlignleft}> {row.activity.user.supplier_profile.work_name}<br /><small>ID : {row.activity.user.sid}</small></td>
                                  <td className={classes.textAlignleft}>{row.num_adults}A/ {row.num_children}C/ {row.num_infants}I</td>

                                  <td className={classes.textAlignleft}>{row.created_at}</td>
                                  <td className={classes.textAlignleft}>{StatusChip(row.status)}</td>
                                  <td className={classes.textAlignleft}><Button variant="contained" color="primary" size="small" component={Link} to={'/BookingDetails/' + row.id} className={classes.button} style={{ background: "#f7a414", alignContent: 'center', textAlign: "center" }} textalign="center">
                                    <b>View </b> </Button></td>
                                </tr>
                              ))}
                            </tbody>
                          </table>

                        </div>


                      </TabContainer>}

                      {this.state.value === 'four' && <TabContainer>

                        <div>



                          <table cellSpacing={0}>
                            <thead style={{ fontSize: 16 }}>
                              <tr>
                                <th className={classes.textAlignCenter}>Tour Date/Ref</th>
                                <th>Tour Name</th>
                                <th className={classes.textAlignCenter}>Supplier</th>
                                <th className={classes.textAlignCenter}>Pax Count</th>
                                <th className={classes.textAlignCenter}>Booking Date/Time</th>
                                <th className={classes.textAlignCenter}>Status</th>
                                <th>&nbsp;</th>
                              </tr>

                            </thead>
                            <tbody style={{ fontSize: 15 }}>
                              {this.props.BookingList.cancelled.map(row => (
                                <tr>
                                  <td className={classes.textAlignleft}>{row.start_date}<br />{row.booking_reference}</td>
                                  <td className={classes.textAlignleft}>{row.activity.title}  <br /><small>Tour ID : {row.activity.tour_id} </small></td>
                                  <td className={classes.textAlignleft}>{row.activity.user.supplier_profile.work_name}<br /><small>ID : {row.activity.user.sid}</small></td>
                                  <td className={classes.textAlignleft}>{row.num_adults}A/ {row.num_children}C/ {row.num_infants}I</td>

                                  <td className={classes.textAlignleft}>{row.created_at}</td>
                                  <td className={classes.textAlignleft}>{StatusChip(row.status)}</td>
                                  <td className={classes.textAlignleft}><Button variant="contained" color="primary" size="small" component={Link} to={'/BookingDetails/' + row.id} className={classes.button} style={{ background: "#f7a414", alignContent: 'center', textAlign: "center" }} textalign="center">
                                    <b>View </b> </Button></td>
                                </tr>
                              ))}
                            </tbody>
                          </table>

                        </div>


                      </TabContainer>}

                      {this.state.value === 'five' && <TabContainer>

                        <div>



                          <table cellSpacing={0}>

                            <thead style={{ fontSize: 16 }}>
                              <tr>
                                <th className={classes.textAlignCenter}>Tour Date/Ref</th>
                                <th>Tour Name</th>
                                <th className={classes.textAlignCenter}>Supplier</th>
                                <th className={classes.textAlignCenter}>Pax Count</th>
                                <th className={classes.textAlignCenter}>Booking Date/Time</th>
                                <th className={classes.textAlignCenter}>Status</th>
                                <th>&nbsp;</th>
                              </tr>

                            </thead>
                            <tbody style={{ fontSize: 15 }}>
                              {this.props.BookingList.completed.map(row => (
                                <tr>
                                  <td className={classes.textAlignleft}>{row.start_date}<br />{row.booking_reference}</td>
                                  <td className={classes.textAlignleft}>{row.activity.title}  <br /><small>Tour ID : {row.activity.tour_id} </small></td>
                                  <td className={classes.textAlignleft}>{row.activity.user.supplier_profile.work_name}<br /><small>ID : {row.activity.user.sid}</small></td>
                                  <td className={classes.textAlignleft}>{row.num_adults}A/ {row.num_children}C/ {row.num_infants}I</td>

                                  <td className={classes.textAlignleft}>{row.created_at}</td>
                                  <td className={classes.textAlignleft}>{StatusChip(row.status)}</td>
                                  <td className={classes.textAlignleft}>
                                    <Button variant="contained" color="primary" size="small" component={Link} to={'/BookingDetails/' + row.id} className={classes.button} style={{ background: "#f7a414", alignContent: 'center', textAlign: "center" }} textalign="center">
                                      <b>View </b> </Button></td>
                                </tr>
                              ))}
                            </tbody>
                          </table>

                        </div>


                      </TabContainer>}
                      {this.state.value === 'six' && <TabContainer>

                        <div>



                          <table cellSpacing={0}>
                            <thead style={{ fontSize: 16 }}>
                              <tr>
                                <th className={classes.textAlignCenter}>Tour Date/Ref</th>
                                <th>Tour Name</th>
                                <th className={classes.textAlignCenter}>Supplier</th>
                                <th className={classes.textAlignCenter}>Pax Count</th>
                                <th className={classes.textAlignCenter}>Booking Date/Time</th>
                                <th className={classes.textAlignCenter}>Status</th>
                                <th>&nbsp;</th>
                              </tr>

                            </thead>
                            <tbody style={{ fontSize: 15 }}>
                              {this.props.BookingList.dispute.map(row => (
                                <tr>
                                  <td className={classes.textAlignleft}>{row.start_date}<br />{row.booking_reference}</td>
                                  <td className={classes.textAlignleft}>{row.activity.title}  <br /><small>Tour ID : {row.activity.tour_id} </small></td>
                                  <td className={classes.textAlignleft}>{row.activity.user.supplier_profile.work_name}<br /><small>ID : {row.activity.user.sid}</small></td>
                                  <td className={classes.textAlignleft}>{row.num_adults}A/ {row.num_children}C/ {row.num_infants}I</td>

                                  <td className={classes.textAlignleft}>{row.created_at}</td>
                                  <td className={classes.textAlignleft}>{StatusChip(row.status)}</td>
                                  <td className={classes.textAlignleft}><Button variant="contained" color="primary" size="small" component={Link} to={'/BookingDetails/' + row.id} className={classes.button} style={{ background: "#f7a414", alignContent: 'center', textAlign: "center" }} textalign="center">
                                    <b>View </b> </Button></td>
                                </tr>
                              ))}
                            </tbody>
                          </table>

                        </div>


                      </TabContainer>}


                    </div>
                  </div>




                </Paper>

              </TableCell>

            </TableRow>

          </TableBody>
        </Table>

      </div>


    </Fragment>
  }
}

Tours.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Tours);